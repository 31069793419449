/* Container หลัก */
.history-container {
    max-width: 1200px;
    height: 75vh;
    width: 70vw;
    margin: 40px auto;
    padding: 30px;
    background: #f9f9f9;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* หัวข้อ */
.history-title {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
    margin: 0;
}

/* ปุ่มด้านบน */
.cut-history-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
}

.cut-history-button {
    background: linear-gradient(135deg, #ff6f61, #ff4757);
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cut-history-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* ตารางที่เลื่อนได้ */
.history-table-container {
    overflow-y: auto;
    max-height: 55vh;
    position: relative;
    background: white;
    border: 1px solid #ddd;
    border-radius: 12px;
}

/* ตาราง */
.history-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 900px;
}

/* หัวตาราง sticky */
.history-table th {
    position: sticky;
    top: 0;
    z-index: 10;
    background: linear-gradient(135deg, #007bff, #00bfff);
    color: white;
    font-weight: bold;
    padding: 9px;
    text-align: center;
    border: 1px solid #ddd;
    border-collapse: collapse;  /* ให้เส้นติดกันระหว่างช่อง */
  
}

/* ข้อมูลในตาราง */
.history-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #f0f0f0;
    background: #fff;
    font-size: 0.95rem;
    transition: background 0.3s ease;
    border: 2px solid #ddd;  /* เส้นขอบทุกช่อง */
}

.history-table tr:nth-child(even) td {
    background: #f9f9f9;
}

/* Hover แถว */
.history-table tr:hover td {
    background: #eaf6ff;
}

/* สถานะสำเร็จ */
.history-status-success {
    color: #28a745;
    font-weight: bold;
}

/* สถานะรอดำเนินการ */
.history-status-pending {
    color: #ff9800;
    font-weight: bold;
}

/* ปุ่มส่ง */
.history-button {
    align-self: center;
    background: linear-gradient(135deg, #1e90ff, #007bff);
    color: white;
    font-weight: bold;
    padding: 12px 25px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;
    width: 120px;
}

.history-button:hover {
    background: linear-gradient(135deg, #007bff, #0056b3);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Scrollbar สวยๆ */
.history-table-container::-webkit-scrollbar {
    width: 8px;
}

.history-table-container::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 4px;
}

.history-table-container::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}
