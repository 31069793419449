:root {
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #FF919D;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}

.App {
  color: var(--black);
  background: linear-gradient(
    106.37deg,
    #ff7f7f 29.63%,  /* แดงอ่อน */
    #ff4d4d 51.55%,  /* แดง */
    #ff1a1a 90.85%   /* แดงเข้ม */
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
}


.AppGlass {
  display: flex;
  height: 97%;
  width: 97%;
  background: var(--glass);
  border-radius: 2rem;
  gap: 16px;
  grid-template-columns: 15rem 1fr; /* คอลัมน์แรกมีขนาด 11rem คอลัมน์ที่สองจะเติมเต็มพื้นที่ที่เหลือ */
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .AppGlass {
    grid-template-columns: 11rem 1fr; /* ยังคงเป็น 2 คอลัมน์ที่มีขนาดเดียวกัน */
    overflow-y: scroll;
  } 
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr; /* เปลี่ยนเป็น 1 คอลัมน์เมื่อหน้าจอเล็ก */
  } 
}

