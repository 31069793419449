/* Product.css */

.product-container {
    max-width: 1000px; 
    width: 70vw;
    margin: auto; 
    padding: 20px; /* เพิ่มระยะห่างภายใน */
    text-align: center; /* จัดกลางข้อความ */
    justify-content: center;
    background-color: #f9f9f9; /* สีพื้นหลัง */
    border-radius: 8px; /* มุมมน */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* เงา */
    max-height: 600px; /* กำหนดความสูงสูงสุด */
    overflow-y: scroll; /* ให้สามารถเลื่อนลงได้ */
    scrollbar-width: none; /* ซ่อนแถบเลื่อนบน Firefox */
  }
  
  .product-container::-webkit-scrollbar {
    display: none; /* ซ่อนแถบเลื่อนบน Chrome/Safari */
  }

  .product-item-wrapper{
    display: flex;
    justify-content: center;
  }
  
  .product-item {
    display: flex; /* ใช้ Flexbox เพื่อจัดวางรายการในแนวนอน */
    justify-content: center; 

    margin: 10px 0; /* ระยะห่างระหว่างสินค้า */
    padding: 10px; /* ระยะห่างภายใน */
    border: 2px solid #b3b2b2; /* ขอบของสินค้า */
    border-radius: 4px; /* มุมมน */
    background-color: #fff; /* สีพื้นหลังสินค้า */
    transition: transform 0.2s; /* เพิ่มเอฟเฟคการเคลื่อนไหว */
    width: auto;
  }
  
  
/*   
  .product-item:hover {
    transform: scale(1.02); 
  } */
  
  .product-item img {
    max-width: 650px; /* ขยายให้พอดีกับความกว้าง */
    border-radius: 4px; /* มุมมนของรูปภาพ */
    margin-bottom: 15px;
    
  }
  
  .product-item p {
    font-size: 1.2rem; /* ขนาดฟอนต์ */
    color: #333; /* สีฟอนต์ */
  }

  /* เพิ่มคลาสสำหรับตัวเลข */
  .lotto-number {
    margin: 10px 0; /* ระยะห่างด้านบนและล่างของตัวเลข */
    font-size: 1.5rem; /* ขนาดฟอนต์ */
    color: #333; /* สีฟอนต์ */
    letter-spacing: 10px; /* เพิ่มระยะห่างระหว่างตัวอักษร */
    border: 1px solid #ddd; /* ขอบของ input */
    border-radius: 4px; /* มุมมน */
    padding: 10px; /* เพิ่มระยะห่างภายใน */
    width: 100px; /* กำหนดให้กว้างเต็มที่ */
    max-width: 500px; /* ความกว้างสูงสุด */
    box-sizing: border-box; /* รวมขอบและระยะห่างภายในในขนาดทั้งหมด */
    text-align: center; /* จัดกลางข้อความ */
    background-color: #f2f2f2; /* สีพื้นหลังของหมายเลข */
}


.lotto-number-container {
  margin-top: 10px; /* เพิ่มระยะห่างด้านบน */
  /* display: flex;  */
  align-items: center; /* จัดให้อยู่กลางในแนวตั้ง */
}

.lotto-input {
  width: 75px; /* ความกว้างของ input สำหรับช่องอื่น */
  height: 50px;
  letter-spacing: 8px;
  text-align: center; /* จัดข้อความให้กลาง */
  margin: 0 10px; /* เพิ่มระยะห่างระหว่าง input */
  padding: 10px; /* เพิ่มระยะห่างภายใน */
  font-size: 1.5rem; /* เพิ่มขนาดฟอนต์ */
  border: 1px solid #5a5959; /* ขอบของ input */
  border-radius: 4px; /* มุมมน */
}


.lotto-input-xxxxxx {
  letter-spacing: 10px;
    width: 250px; /* ปรับความกว้างสำหรับช่อง xxxxxx */
}

/* .separator {
    margin: 0 5px; 
} */

.radio-container {
  display: flex;
  flex-direction: column; /* จัดปุ่ม radio ให้เป็นคอลัมน์ */
  align-items: flex-start; /* จัดให้อยู่ด้านซ้าย */
  margin-top: 10px; /* เพิ่มระยะห่างด้านบน */
}

.radio-container label {
  margin-right: 10px; /* ระยะห่างระหว่างข้อความและปุ่ม */
  font-size: 1rem; /* ขนาดฟอนต์ */
}

.radio-group {
  display: flex;
  flex-direction: column; /* จัดปุ่ม radio ให้เป็นคอลัมน์ */
  justify-content: space-between;
}

.radio-group input {
  margin: 5px 0; /* ระยะห่างระหว่างปุ่ม */
}

.right-column {
  display: flex; /* ใช้ Flexbox สำหรับคอลัมน์ขวา */
  flex-direction: column; /* จัดเป็นคอลัมน์ */
  justify-content: center; /* จัดกลางในแนวตั้ง */
  margin-left: 40px; /* ระยะห่างด้านซ้าย */
}

.note-back-button {
  background-color: red; /* กำหนดสีพื้นหลังเป็นสีแดง */
  color: white; /* กำหนดสีข้อความเป็นสีขาว */
  border: none; /* ไม่ให้มีกรอบ */
  padding: 10px 20px; /* เพิ่มช่องว่างรอบปุ่ม */
  font-size: 16px; /* ขนาดข้อความ */
  cursor: pointer; /* แสดงเคอร์เซอร์เป็น pointer เมื่อวางเมาส์ */
  border-radius: 5px; /* ทำให้มุมปุ่มมน */
  transition: background-color 0.3s; /* เพิ่มการเปลี่ยนสีเมื่อวางเมาส์ */
}

/* เพิ่มเอฟเฟกต์เมื่อวางเมาส์ */
.note-back-button:hover {
  background-color: darkred; /* เปลี่ยนสีพื้นหลังเมื่อวางเมาส์ */
}

.note-container-wrapper {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}

.note-container {
  display: flex;
  align-items: center;
  width: 780px; 
  padding: 10px;
  justify-content: space-between;
  background-color: #f9f9f9;
}


.note-input {
  flex: 1; /* ทำให้ช่องกรอกหมายเหตุขยายเต็มพื้นที่ */
  margin-right: 10px; /* ระยะห่างระหว่างช่องกรอกกับปุ่ม */
  padding: 10px; /* ขนาด padding ภายใน */
  border: 1px solid #ccc; /* ขอบของช่องกรอก */
  border-radius: 5px; /* มุมขอบมน */
  font-size: 16px; /* ขนาดตัวอักษร */
  outline: none; /* เอาเส้นขอบที่เกิดจากการเลือก */
  transition: border-color 0.3s; /* การเปลี่ยนสีขอบเมื่อโฟกัส */
}

.note-input:focus {
  border-color: #007bff; /* เปลี่ยนสีขอบเมื่อโฟกัส */
}

.note-button {
  padding: 10px 15px; /* ขนาดของปุ่ม */
  background-color: #007bff; /* สีพื้นหลังของปุ่ม */
  color: white; /* สีตัวอักษรของปุ่ม */
  border: none; /* ไม่มีขอบ */
  border-radius: 5px; /* มุมขอบมน */
  font-size: 16px; /* ขนาดตัวอักษร */
  cursor: pointer; /* แสดงเป็นมือเมื่อวางเมาส์ */
  transition: background-color 0.3s; /* การเปลี่ยนสีพื้นหลังเมื่อโฟกัส */
}

.note-button:hover {
  background-color: #0056b3; /* เปลี่ยนสีพื้นหลังเมื่อวางเมาส์ */
}

.note-button:active {
  background-color: #004494; /* เปลี่ยนสีพื้นหลังเมื่อกด */
}


.popup-notification {
  position: fixed; /* ใช้ fixed เพื่อให้มันอยู่กลางจอ */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* ฉากหลังโปร่งใสที่เข้มขึ้น */
  display: flex; /* ใช้ flexbox เพื่อจัดตำแหน่งเนื้อหา */
  justify-content: center; /* จัดกลางแนวนอน */
  align-items: center; /* จัดกลางแนวตั้ง */
  z-index: 1000; /* ให้อยู่เหนือเนื้อหาอื่น */
}

.popup-content {
  width: 350px;
  background-color: #ffffff; /* สีพื้นหลังของ Popup */
  padding: 30px; /* ระยะห่างภายใน */
  border-radius: 15px; /* มุมโค้งที่ใหญ่ขึ้น */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* เงาที่ชัดเจนขึ้น */
  text-align: center; /* จัดข้อความให้อยู่กลาง */
  animation: fadeIn 0.3s; /* เพิ่มการแอนิเมชัน */
  display: flex; /* ใช้ flexbox เพื่อจัดตำแหน่งปุ่ม */
  flex-direction: column; /* แนวตั้ง */
  justify-content: center; /* จัดให้มีที่ว่างเท่ากันรอบๆ */
  height: 200px; /* ความสูงที่กำหนดให้ Popup */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* เริ่มจากโปร่งใส */
    transform: translateY(-20px); /* เริ่มจากด้านบน */
  }
  to {
    opacity: 1; /* เปลี่ยนเป็นทึบ */
    transform: translateY(0); /* กลับสู่ตำแหน่งเดิม */
  }
}

.popup-message {
  font-size: 18px; /* ขนาดฟอนต์ข้อความ */
  color: #333; /* สีข้อความ */
  margin-bottom: 20px; /* ระยะห่างด้านล่าง */
  line-height: 1.4; /* ระยะห่างระหว่างบรรทัด */
}

.popup-button {
  padding: 8px 16px; /* ขนาดของปุ่มที่เล็กลง */
  border: none; /* ไม่มีกรอบ */
  border-radius: 5px; /* มุมโค้ง */
  background-color: #4CAF50; /* สีพื้นหลัง */
  color: white; /* สีข้อความ */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์เมื่อชี้ไปที่ปุ่ม */
  font-size: 16px; /* ขนาดฟอนต์ที่เล็กลง */
  transition: background-color 0.3s ease, transform 0.2s; /* เพิ่มการเปลี่ยนสีและขยาย */
  max-width: 150px; /* กำหนดความกว้างสูงสุดของปุ่ม */
  margin: 0 auto; /* จัดให้อยู่กลาง */
}

.popup-button:hover {
  background-color: #45a049; /* สีเมื่อชี้ไปที่ปุ่ม */
  transform: scale(1.05); /* ขยายขนาดปุ่มเมื่อชี้ */
}

.popup-button:focus {
  outline: none; /* ลบเส้นขอบเมื่อปุ่มถูกเลือก */
}


.remark-input {
  width:600px ;
  margin-top: 30px;
  height: 20px;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #454545;
  resize: none;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}


/* Styles for confirm and cancel buttons */
.confirm-button {
  background-color: #28a745; /* Green background */
  color: white;
  border: none;
  padding: 12px 24px; /* Larger padding for a bigger button */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px; /* Slightly larger font size */
  width: 120px; /* Set a fixed width for shorter buttons */
}

.confirm-button:hover {
  background-color: #218838; /* Darker green on hover */
}

.cancel-button {
  background-color: #dc3545; /* Red background */
  color: white;
  border: none;
  padding: 12px 24px; /* Larger padding for a bigger button */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px; /* Slightly larger font size */
  width: 120px; /* Set a fixed width for shorter buttons */
}

.cancel-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

.popup-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
  gap: 20px; /* Increase gap to add more space between buttons */
}


.popup-buttons button {
  font-size: 16px;
  cursor: pointer;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  text-align: center;
  animation: slideUp 0.3s ease-out;
}

.modal-content h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

/* Button Styles */
.modal-buttons {
  display: flex;
  justify-content: space-around;
}

.btn-confirm, .btn-cancel {
  padding: 12px 30px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-confirm {
  background-color: #4caf50;
  color: white;
}

.btn-confirm:hover {
  background-color: #45a049;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
}

.btn-cancel:hover {
  background-color: #e53935;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}

/* ปรับการแสดงผลของโมดัลแจ้งเตือน */
.alert-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* พื้นหลังมืด */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* ให้อยู่บนสุด */
}

/* กำหนดรูปแบบการแสดงเนื้อหาในโมดัล */
.alert-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.alert-content p {
  font-size: 24px; /* เพิ่มขนาดตัวอักษร */
  font-weight: bold; /* ทำให้ข้อความหนาขึ้น */
  color: #333; /* กำหนดสีข้อความ */
}

/* ปรับปุ่มปิด */
.close-btn {
  background-color: #ff5733;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
}

.close-btn:hover {
  background-color: #e74c3c;
}



.no-data-message {
  font-size: 1.2em;
  color: red;
  text-align: center;
  margin-top: 20px;
}
