.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 4rem;
  transition: all 300ms ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* เพิ่มเงาให้กับ sidebar */
  width: 250px;
}
/* logo */
.bars{
  display: none;
}

.logo {
  display: flex;
  height: 5rem;
  font-weight: bold;
  font-size: 22px;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 4%;
}
.logo > span > span {
  color: var(--pink);
}

.logo > img {
  width: 3rem;
  height: 3rem;
}


/* menu */
.menu {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 14px;
}

.menuItem:hover {
  cursor: pointer;
}

.menu .menuItem:last-child {
  position: absolute;
  bottom: 2.3rem;
  width: 100%;
}

.active {
  background: var(--activeItem);
  margin-left: 0;
}
.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--pink);
  margin-right: calc(1rem - 8px);
}


/* Tablets */
@media screen and (max-width: 1200px) {
  .menuItem>span{
    display: none;
  }
  .logo{
    display: none;
  }
}



@media screen and (max-width: 768px) {
  .sidebar{
    position: fixed;
    z-index: 9;
    background: #ffe0e0;
    width: 55%;
    padding-right: 1rem;
    height: 100%;
  }
  .menuItem>span{
    display: block;
  }
  .logo{
    display: flex;
  }
  .menu .menuItem:last-child {
    position: relative;
    margin-top: 6rem;
  }
  .bars{
    display: flex;
    position: fixed;
    top: 2rem;
    left:60%;
    padding:10px;
    border-radius: 10px;
    z-index: 9;
  }
  .close{
    left: -60%;
  }
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* พื้นหลังโปร่งใส */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* ทำให้ Popup อยู่ด้านบนสุด */
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
