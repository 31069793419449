.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f4f4f9; */
  padding: 20px;
  width: 70vw;
  border-radius: 15px; /* เพิ่มมุมโค้ง */
  margin: 30px auto;
  overflow-y: scroll; /* ให้สามารถเลื่อนลงได้ */
  scrollbar-width: none; /* ซ่อนแถบเลื่อนบน Firefox */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
}

.profile-container::-webkit-scrollbar {
  display: none; /* ซ่อนแถบเลื่อนบน Chrome/Safari */
}


.profile-card {
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 800px;

  text-align: center;
}


/* .profile-card-d {
  background: #ffffff;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  text-align: center;

} */

.loading-message {
  font-size: 18px;
  font-weight: 500;
  color: #555555;
  margin: 20px 0;
}

.check-info .styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.styled-table thead tr {
  background-color: #4caf50;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 2px solid #c6c4c4;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.error-message {
  color: #e74c3c;
  font-size: 16px;
  margin-top: 10px;
}


.buttongroup {
  display: flex;
  justify-content: flex-start; /* จัดปุ่มให้อยู่ด้านซ้าย */
  gap: 20px; /* เว้นระยะห่างระหว่างปุ่ม */
  margin-bottom: 10px; /* เว้นระยะห่างระหว่างปุ่มกับตาราง */
}

.check-error-button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background-color: #dc3545; /* สีแดงสำหรับ "ตรวจผิด" */
}

.check-error-Lottomistake {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background-color: #e29b29; /* สีแดงสำหรับ "ตรวจผิด" */
}
.checkbutton {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background-color: #1f9927; /* สีแดงสำหรับ "ตรวจผิด" */
}

.detailsbutton {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background-color: #dc3545; /* สีแดงสำหรับ "ตรวจผิด" */
}

.check-error-button:hover {
  opacity: 0.9; /* เพิ่มเอฟเฟกต์ hover */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background-color: #007bff; /* สีฟ้าสำหรับ Dropdown */
}

.dropdown-button:hover {
  opacity: 0.9; /* เพิ่มเอฟเฟกต์ hover */
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0; /* จัดเมนู dropdown ให้อยู่ทางซ้าย */
  background-color: #e6e6e6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 150px;
  z-index: 1;
}

.dropdown-content a {
  color: #000;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block; /* แสดงเมนูเมื่อ hover */
}


/* เพิ่มระยะห่างระหว่างตัวเลขทั้งหมด */
.correct-or{
  color: green;
  /* letter-spacing: 3px;  */
}

/* สีแดงสำหรับตัวเลขที่ไม่ตรงกันใน Error */
/* เพิ่มระยะห่างระหว่างตัวเลขใน Error */
.error-or {
  /* letter-spacing: 3px;   */
  color: red;           /* ตัวเลขผิดจะมีสีแดง */
  font-weight: bold;    /* ตัวเลขผิดจะหนา */
}

/* 🔹 Loading Text */
.loading-text {
  text-align: center;
  font-size: 20px;
  color: #ff6b6b;
  margin-top: 20px;
  font-weight: bold;
}

/* 🔹 Profile Container */
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* 🔹 Card */
.profile-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 900px;
}

/* 🔹 Button */
.check-error-button {
  /* background: #ff6b6b; */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
}

.check-error-button:hover {
  background: #e63946;
}

/* 🔹 Table */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.styled-table th, .styled-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.styled-table th {
  background: #f8f9fa;
  font-weight: bold;
}

.styled-table .hover-row:hover {
  background: #f1f1f1;
  transition: 0.3s;
}

/* 🔹 View Image */
.view-image {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  transition: 0.3s;
}

.view-image:hover {
  color: #0056b3;
}

/* 🔹 No Data */
.no-data {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: #ff6b6b;
}

/* 🔹 Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  animation: slideDown 0.3s ease-in-out;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 10px;
}

/* 🔹 Close Button */
.close-button {
  margin-top: 10px;
  padding: 8px 12px;
  background: #ff6b6b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
}

.close-button:hover {
  background: #e63946;
}

/* 🔹 Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-20px); }
  to { transform: translateY(0); }
}
