.notification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); /* ปรับความเข้มของพื้นหลัง */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* ให้อยู่ด้านบนสุด */
    transition: opacity 0.3s ease; /* เพิ่มการเคลื่อนไหว */
}

.notification-box {
    background: #ffffff; /* สีพื้นหลังของกล่อง */
    padding: 40px; /* เพิ่ม padding */
    border-radius: 12px; /* ปรับรัศมีของมุมให้กลมขึ้น */
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.6); /* ปรับความเข้มของเงา */
    text-align: center;
    max-width: 450px; /* จำกัดความกว้างของกล่อง */
    width: 90%; /* ความกว้างสูงสุดเป็น 90% ของหน้าจอ */
    position: relative; /* ทำให้สามารถใช้ position ของลูกในภายหลังได้ */
    animation: fadeIn 0.5s ease; /* เพิ่มแอนิเมชันเข้ามา */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px); /* เริ่มจากด้านบน */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.notification-title {
    margin: 0;
    font-size: 2.5em; /* ขยายขนาดตัวอักษรให้ใหญ่ขึ้น */
    font-weight: bold; /* ตัวหนา */
    color: #4A90E2; /* เปลี่ยนเป็นสีน้ำเงินที่นุ่มนวล */
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* เพิ่มเงาให้ข้อความ */
}

.notification-message {
    margin: 15px 0; /* ปรับระยะห่างบนและล่าง */
    font-size: 1.5em; /* ขยายขนาดตัวอักษรให้ใหญ่ขึ้น */
    color: #666; /* เปลี่ยนสีข้อความให้เข้มขึ้น */
    line-height: 1.5; /* เพิ่มระยะห่างระหว่างบรรทัด */
}

.notification-icon {
    font-size: 40px; /* ขนาดของไอคอน */
    color: #4A90E2; /* สีของไอคอน */
    margin-bottom: 15px; /* ระยะห่างใต้ไอคอน */
}

.notification-btn {
    background: #4A90E2; /* เปลี่ยนสีพื้นหลังของปุ่ม */
    color: white; /* สีตัวอักษร */
    border: none;
    border-radius: 5px;
    padding: 12px 25px; /* ปรับ padding */
    cursor: pointer;
    font-size: 1.2em; /* ขยายขนาดตัวอักษรให้ใหญ่ขึ้น */
    transition: background 0.3s ease, transform 0.2s ease; /* เพิ่มการเคลื่อนไหว */
    position: relative; /* เพื่อใช้ effect เมื่อกดปุ่ม */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* เพิ่มเงาให้กับปุ่ม */
}

.notification-btn:hover {
    background: #357ABD; /* สีเมื่อชี้เมาส์ */
    transform: translateY(-2px); /* ยกปุ่มขึ้นเล็กน้อยเมื่อชี้เมาส์ */
}

.notification-btn:active {
    transform: translateY(1px); /* ย่อปุ่มเมื่อกด */
}

.notification-close {
    
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    color: #888; /* สีของปุ่มปิด */
    cursor: pointer;
    transition: color 0.3s ease;
}

.notification-close:hover {
    color: #ff4d4d; /* สีเมื่อชี้เมาส์ */
}
