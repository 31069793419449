/* การจัดการ container หลัก */
.lottery-container {
  width: 70vw;
  margin: 20px auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: center;
}

/* สไตล์สำหรับหัวข้อหลัก */
.lottery-title {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #3498db;
  font-weight: bold;
  text-transform: uppercase;
}

/* การจัดการกริดสำหรับการจัดแบบ 3 คอลัมน์ */
.lottery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px; /* เพิ่มช่องว่างระหว่างคอลัมน์ */
  justify-items: center;
}

/* การจัดการรอบตรวจหวย */
.lottery-section {
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 15px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  transition: transform 0.3s;
  margin-bottom: 30px; /* เพิ่มช่องว่างด้านล่าง */
}

/* สไตล์สำหรับหัวข้อย่อยในแต่ละคอลัมน์ */
.lottery-subtitle {
  font-size: 1.8em;
  color: #2c3e50;
  margin-bottom: 15px;
  text-transform: uppercase;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

/* สไตล์ข้อมูลลอตเตอรี่ */
.lottery-info {
  font-size: 1.3em;
  margin: 10px 0;
  color: #34495e;
}

.lottery-info span {
  font-weight: bold;
  color: #e74c3c;
}

/* สไตล์สำหรับปุ่ม */
.lottery-btn {
  background-color: #e74c3c;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px; /* เพิ่มช่องว่างด้านบน */
  transition: background-color 0.3s ease, transform 0.3s;
  width: 100%;
}

.lottery-btn:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

/* สไตล์สำหรับหน้าจอเล็ก */
@media (max-width: 768px) {
  .lottery-grid {
    grid-template-columns: 1fr; /* เปลี่ยนเป็น 1 คอลัมน์เมื่อหน้าจอเล็กลง */
    gap: 30px; /* ลดช่องว่างระหว่างคอลัมน์สำหรับหน้าจอเล็ก */
  }

  .lottery-btn {
    width: 100%;
  }
}
