/* Main container for the user management section */
.user-management {
    padding: 20px;
    width: 70vw;
    margin: 30px auto;
    background-color: #f9f9f9;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: scroll; /* ให้สามารถเลื่อนลงได้ */
    scrollbar-width: none; /* ซ่อนแถบเลื่อนบน Firefox */
  }


  .user-management::-webkit-scrollbar {
    display: none; /* ซ่อนแถบเลื่อนบน Chrome/Safari */
  }

  .user-management h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  /* User form layout */
  .user-form {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .user-form input,
  .user-form select {
    flex: 1 1 200px;
    padding: 8px;
    border: 1px solid #8d8d8d;
    border-radius: 4px;
  }
  
  .add-button {
    display: flex;
    align-items: center;  /* จัดตำแหน่งไอคอนและข้อความให้ตรง */
    margin-top: 0;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  

  .add-button:hover {
    background-color: #45a049;
  }
  
  
  /* Table styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid #c1c1c1;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  td input {
    width: 100%;
    height: 30px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
  }
  
  td input:focus {
    border-color: #4CAF50;
  }


  .button-group{
    gap: 15px;
    display: flex;
    justify-content: center; /* จัดปุ่มให้อยู่กึ่งกลางในแนวนอน */
    align-items: center; /* จัดปุ่มให้อยู่กึ่งกลางในแนวตั้ง */
  }


.delete-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px; 
  height: 35px;   
  
}

.button-submit {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px; 
    height: 35px;   
    
  }
  
  .button-submit {
    /* width: 25px; */
    margin-top: auto;
    background-color: #4CAF50;
    color: white;
  }
  
  .delete-button {
    /* width: auto; */
    margin-top: auto;
    background-color: #f44336;
    color: white;
  }
  

 .button-submit:hover {
    background-color: #398b3d;
  }
   
  .delete-button:hover {
    background-color: #b92828;
  }
  
  /* Action button container to align buttons */
  button {
    margin-top: 5px;
  }
  