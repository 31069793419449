.scrollable-container {
  margin: 0 0 30px 30px;
  max-height: 100vh; /* จำกัดความสูงไม่ให้เต็มหน้าจอ */
  overflow-y: auto; /* ทำให้สามารถเลื่อนในแนวตั้งได้ */
  padding: 20px; /* เพิ่ม padding ถ้าต้องการ */
  box-sizing: border-box; /* ให้ padding ไม่ทำให้ขนาดของ container เปลี่ยน */
  width: 75vw;
}
.scrollable-container::-webkit-scrollbar {
  display: none;
  /* ซ่อน scrollbar */
}

.digit-item {
  display: flex; /* ใช้ flexbox สำหรับจัดการตำแหน่ง */
  align-items: center; /* จัดให้กลางในแนวตั้ง */
  justify-content: space-between; /* จัดให้เลขและเช็กบล็อกอยู่ที่ปลายทั้งสองฝั่ง */
}

.digit-checkbox {
  margin-left: 10px; /* ระยะห่างจากข้อความ */
  width: 20px; /* กำหนดความกว้างของเช็กบล็อก */
  height: 20px; /* กำหนดความสูงของเช็กบล็อก */
}


.position-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* สร้าง 3 คอลัมน์ */
  gap: 20px; /* ระยะห่างระหว่างแต่ละ item */
}


.position-item {
  border-radius: 10px; /* มุมโค้งมน */
  padding: 20px; /* ระยะห่างภายใน item */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* เงาใต้ item */
  transition: transform 0.3s, box-shadow 0.3s; /* การเคลื่อนไหวเมื่อ hover */
}

.position-item h3 {
  margin: 0; /* ลบ margin ด้านบนและด้านล่าง */
  color: #333; /* สีข้อความ */
  font-size: 1.3em; /* ขนาดฟอนต์ใหญ่ขึ้น */
  font-weight: bold; /* ตัวหนา */
  margin-bottom: 15px; /* ระยะห่างระหว่างหัวข้อกับเนื้อหา */
  text-align: center; /* จัดข้อความให้อยู่กลาง */
}

.position-item ul {
  list-style-type: none; /* ลบจุดหน้ารายการ */
  padding: 0; /* ลบ padding */
}

.position-item li {
  display: flex; /* ใช้ flexbox สำหรับจัดการตำแหน่ง */
  align-items: center; /* จัดให้กลางในแนวตั้ง */
  justify-content: space-around; /* จัดให้เลขและเช็กบล็อกอยู่ที่ปลายทั้งสองฝั่ง */
  padding: 5px; /* ระยะห่างภายในรายการ */
}

.digit-checkbox {
  margin-left: 10px; /* ระยะห่างจากข้อความ */
  width: 20px; /* กำหนดความกว้างของเช็กบล็อก */
  height: 20px; /* กำหนดความสูงของเช็กบล็อก */
  cursor: pointer; /* แสดงว่าคลิกได้ */
}


/* ซ่อน scrollbar ในเบราว์เซอร์ Chrome, Safari และ Edge */
.scrollable-container::-webkit-scrollbar {
  width: 8px; /* ขนาดของ scrollbar */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888; /* สีของ scrollbar */
  border-radius: 10px; /* ทำให้ scrollbar มน */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* สีเมื่อ hover บน scrollbar */
}

.CardonestPrize {
  background-color: white; /* สีพื้นหลังของการ์ด */
  border-radius: 15px; /* มุมโค้งมนมากขึ้น */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* เงาใต้การ์ด */
  padding: 30px; /* ระยะห่างภายในการ์ด */
  max-width: 1000px; /* ความกว้างสูงสุดของการ์ด */
  width: 100%; /* ทำให้การ์ดเต็มความกว้าง */
  text-align: center; /* จัดตำแหน่งข้อความให้อยู่กลาง */
  margin: 60px auto; /* จัดให้อยู่ตรงกลางและเพิ่มระยะห่างจากด้านบน */
  transition: transform 0.3s; /* เพิ่มการเคลื่อนไหวเมื่อ hover */
  min-height: 100px; /* กำหนดความสูงขั้นต่ำของการ์ด */
}

/* .CardonestPrize:hover {
  transform: scale(1.05); 
} */


.new-numbers-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 4 คอลัมน์ */
  gap: 20px; /* ระยะห่างระหว่าง item */
  margin-top: 30px;
}

.new-number-item {
  /* background-color: #f9f9f9;  */
  border-radius: 10px; /* มุมโค้งมน */
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* เงา */
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  background-color: #5b72cf;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


.loading-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  /* background-color: #f0f0f0;  */
}

.loading-spinner {
  border: 8px solid #f3f3f3; 
  border-top: 8px solid #ff0000; /* ขอบด้านบนที่เป็นสีฟ้า */
  border-radius: 50%; /* ทำให้เป็นวงกลม */
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite; /* แอนิเมชันหมุน */
}

@keyframes spin {
  0% {
      transform: rotate(0deg); /* เริ่มที่ 0 องศา */
  }
  100% {
      transform: rotate(360deg); /* หมุนจนถึง 360 องศา */
  }
}
.history-data {
/* margin-top: 20px; */
padding: 3px;
/* background-color: #ffffff; */
border-radius: 8px;
/* border: 1px solid #ddd; */
}

.history-item {
font-size: 16px;
color: #333;
/* margin: 10px 0; */
}

.history-item span {
font-weight: bold;
color: #007bff; /* สีที่ใช้เน้นข้อมูล */
}


.container-box{
display: flex;
margin: 30px auto;
justify-content: center;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* เงา */
background-color: #f9f9f9; 
border-radius: 10px;
}

.box-container{
display: flex; /* ใช้ flexbox สำหรับจัดการตำแหน่ง */
  flex-direction: column; /* จัดให้เป็นแนวตั้ง */
  align-items: center; /* จัดกลางในแนวนอน */
  padding: 20px; /* เพิ่ม padding รอบๆ */
  border-radius: 10px; /* มุมโค้งมน */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  */
  /* background-color: #f9f9f9;  */
  width: 300px; /* ความกว้างของ container */
  margin: 50px 50px 50px 50px; 
}

.quantity-container { /* เปลี่ยนชื่อ class ที่นี่ */
  display: flex; /* ใช้ flexbox สำหรับจัดการตำแหน่ง */
  flex-direction: column; /* จัดให้เป็นแนวตั้ง */
  align-items: center; /* จัดกลางในแนวนอน */
  padding: 20px; /* เพิ่ม padding รอบๆ */
  border-radius: 10px; /* มุมโค้งมน */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  background-color: #f9f9f9;  */
  width: 300px; /* ความกว้างของ container */
  margin: 50px 50px 50px 50px; 
  
}


.quantity-label { /* เปลี่ยนชื่อ class ที่นี่ */
  font-size: 20px;
  font-weight: bold; /* ตัวหนา */
  color: #333; /* สีข้อความ */
  margin-bottom: 15px; /* ระยะห่างด้านล่าง */
}

.quantity-labeltop { /* เปลี่ยนชื่อ class ที่นี่ */
  font-size: 20px;
  font-weight: bold; /* ตัวหนา */
  color: #333; /* สีข้อความ */
 
}

.date-picker {
  display: flex;
  align-items: center; /* จัดให้อยู่ในแนวเดียวกัน */
  /* width: 100%; */
  margin-top: 15px; /* ระยะห่างด้านบน */
  margin-bottom: 15px;
}

.date-pickerbt {
  display: flex;
  align-items: center; /* จัดให้อยู่ในแนวเดียวกัน */
  /* width: 100%; */
  margin-top: 15px; /* ระยะห่างด้านบน */
  /* margin-bottom: 15px; */
}

.date-picker label {
  font-size: 16px;
  font-weight: 500;
  color: #495057;
  margin-right: 10px; /* ระยะห่างระหว่างข้อความและช่องวันที่ */
}

.date-pickerbt label {
  font-size: 16px;
  font-weight: 500;
  color: #495057;
  margin-right: 10px; /* ระยะห่างระหว่างข้อความและช่องวันที่ */
}

.react-datepicker-wrapper {
  width: auto; /* ให้ขนาดช่องวันที่ปรับตามข้อความ */
  flex-grow: 1; /* ให้ขนาดเต็มที่เหลืออยู่ */
}

.react-datepicker__input-container input {
  padding: 8px 10px;
  font-size: 16px;
  color: #333;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #f8f9fa;
  width: 100%;
  max-width: 150px; /* กำหนดความกว้างสูงสุดของช่องวันที่ */
  transition: border-color 0.3s ease;
}

.react-datepicker__input-container input:hover {
  border-color: #5b72cf; /* สีขอบเมื่อ hover */
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #5b72cf; /* สีขอบเมื่อ focus */
  box-shadow: 0 0 5px rgba(91, 114, 207, 0.5); /* เพิ่มเงาเมื่อ focus */
}


.create-button, .submit-button, .excel-button {
  flex: 1; /* ให้ปุ่มทั้งสองขยายเท่ากัน */
  padding: 10px 20px; /* ขนาดของปุ่ม */
  margin: 0 5px; /* ระยะห่างระหว่างปุ่ม */
  color: #fff; /* สีตัวอักษร */
  border: none; /* ไม่มีเส้นขอบ */
  font-size: 16px;
  border-radius: 5px; /* ทำให้มุมปุ่มโค้ง */
  cursor: pointer; /* เปลี่ยน cursor เมื่อ hover */
  transition: background-color 0.3s ease; /* ทำให้สีพื้นหลังเปลี่ยนอย่างราบรื่น */
}

.create-button {
  margin-top: 20px;
  background-color: #5b72cf; /* สีพื้นหลังสำหรับปุ่ม Build */
}

.create-button:hover {
  background-color: #4a5cb0; /* เปลี่ยนสีเมื่อ hover */
}

.excel-button {
  margin-top: 20px;
  background-color: #0033ff; /* สีพื้นหลังสำหรับปุ่ม Build */
}

.excel-button:hover {
  background-color: #3358e9; /* เปลี่ยนสีเมื่อ hover */
}


.submit-button {
  margin-top: 20px;
  background-color: #28a745; /* สีพื้นหลังสำหรับปุ่ม Submit */
}

.submit-button:hover {
  background-color: #218838; /* เปลี่ยนสีเมื่อ hover */
}

.disconnected {
  margin: 0;
  color: red;
  font-size: 18px; /* ขนาดข้อความ */
}

.connected {
  margin: 0;
  color: green;
  font-size: 18px; /* ขนาดข้อความ */
  
}


.header {
  display: flex;
  align-items: center;
  justify-content: center; /* กึ่งกลางแนวนอน */
  gap: 10px; /* เพิ่มช่องว่างระหว่างข้อความกับ Dropdown */
  width: 100%; 
}

.amount-label {
  font-size: 16px;
  color: #555;
}

.quantity-dropdown {
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 100px;
}



.lotto-check-count {

  padding: 10px; /* เพิ่มพื้นที่ภายใน */
  border-radius: 8px; /* มุมโค้งของกล่อง */
 
}

.count-text {
  color: #000000; /* สีข้อความเมื่อมีข้อมูล */
  /* font-size: 18px; */
  font-weight: bold;
}

.no-data-text {
  color: #d32f2f; /* สีข้อความเมื่อไม่มีข้อมูล */
  font-size: 16px;
}

.count-text, .no-data-text {
  margin: 0;
  padding: 0;
}


.device span {
  color: #28a745; /* เปลี่ยนข้อความเป็นสีฟ้า */
}

.checkbox-options {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}


.device-checkbox-options {
  display: flex; /* ใช้ Flexbox เพื่อจัดเรียงในแนวนอน */
  flex-wrap: wrap; /* ช่วยให้แถวใหม่เริ่มต้นถ้ามีจำนวนอุปกรณ์มากเกินไป */
  gap: 10px; /* กำหนดระยะห่างระหว่างแต่ละ checkbox */
}

.device-checkbox {
  display: flex;
  align-items: center; /* จัดแนวแนวตั้งให้กับ checkbox และ label */
}
