.login-main {
    display: flex; /* จัดแถวให้เป็นแนวนอน */
    height: 90vh; /* ความสูงของหน้า */

}

.login-left {
    flex: 1; /* พื้นที่ 1 ส่วนจาก 2 ของหน้าจอ */
    display: flex;
    justify-content: center; /* จัดกลางในแนวนอน */
    align-items: center; /* จัดกลางในแนวตั้ง */
    background-size: cover; /* ให้ภาพเต็มพื้นที่ */
    background-position: center; /* จัดตำแหน่งภาพให้กลาง */
    position: relative; /* ทำให้ภาพมีตำแหน่งที่กำหนด */
}

.login-left img {
    width: 900px; /* ให้รูปภาพมีความกว้าง 100% ของพื้นที่ที่มี */
    height: auto; /* ให้ความสูงปรับตามอัตราส่วน */
    max-width: auto; /* ความกว้างสูงสุดของรูปภาพ */
    border-radius: 12px; /* มุมโค้งของรูปภาพ */
    object-fit: cover; /* ให้รูปภาพครอบคลุมพื้นที่โดยไม่เสียสัดส่วน */
  
}


.login-right {
    flex: 2; /* พื้นที่ 1 ส่วนจาก 2 ของหน้าจอ */
    display: flex;
    flex-direction: column; /* จัดฟอร์มในแนวตั้ง */
    justify-content: center; /* จัดกลางในแนวตั้ง */
    align-items: center; /* จัดกลางในแนวนอน */
    height: 100%; /* กำหนดให้มีความสูงเต็ม */
}

.login-right-container {
    width: 350px; /* ความกว้างเต็ม */
    max-width: 500px; /* ความกว้างสูงสุดของฟอร์ม */
    padding: 30px; /* ช่องว่างรอบๆ ฟอร์ม */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* เงาฟอร์มที่มีความลึกมากขึ้น */
    border-radius: 12px; /* มุมโค้ง */
    background-color: rgba(255, 255, 255, 0.9); /* สีพื้นหลังโปร่งแสง */
    transition: box-shadow 0.3s; /* แอนิเมชันเมื่อมีการโฮเวอร์ */
    position: relative; /* ทำให้ฟอร์มอยู่ในตำแหน่งที่กำหนด */
    backdrop-filter: blur(10px); /* เพิ่มฟิลเตอร์เบลอพื้นหลัง */
    margin-left: 90px;
}


.login-right-container:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* เงาเมื่อมีการโฮเวอร์ */
}

h2 {
    font-family: 'Arial', sans-serif; /* เปลี่ยนแบบอักษร */
    margin-bottom: 10px; /* ช่องว่างด้านล่างของหัวข้อ */
}

p {
    font-size: 14px; /* ขนาดตัวอักษร */
    margin-bottom: 20px; /* ช่องว่างด้านล่าง */
}

.input-field {
    width: 300px; /* กว้างเต็ม */
    padding: 12px; /* ช่องว่างในช่องกรอก */
    margin-bottom: 15px; /* ช่องว่างระหว่างช่องกรอก */
    border: 1px solid #ccc; /* เส้นขอบ */
    border-radius: 8px; /* มุมโค้ง */
    font-size: 16px; /* ขนาดตัวอักษร */
    transition: border-color 0.3s; /* แอนิเมชันเมื่อเปลี่ยนสีขอบ */
}

.input-field:focus {
    border-color: #007bff; /* เปลี่ยนสีขอบเมื่อฟอร์มถูกเลือก */
    outline: none; /* เอาเส้นขอบที่ไม่ต้องการออก */
}

.show-password {

    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เมื่อชี้ไปที่ปุ่ม */
    color: #007bff; /* เปลี่ยนสีของปุ่ม */
}

.show-password:hover {
    text-decoration: underline; /* เพิ่มขีดเส้นใต้เมื่อโฮเวอร์ */
}

.login-button {
    background-color: #007bff; /* สีพื้นหลังของปุ่ม */
    color: white; /* สีตัวอักษร */
    padding: 12px; /* ช่องว่างภายในปุ่ม */
    border: none; /* ไม่ต้องการเส้นขอบ */
    border-radius: 8px; /* มุมโค้ง */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือเมื่อชี้ที่ปุ่ม */
    font-size: 16px; /* ขนาดตัวอักษร */
    transition: background-color 0.3s; /* แอนิเมชันเมื่อมีการโฮเวอร์ */
    margin:  10px 0;
}

.login-button:hover {
    background-color: #0056b3; /* เปลี่ยนสีปุ่มเมื่อโฮเวอร์ */
}

.login-bottom-p {
    margin-top: 20px; /* ช่องว่างด้านบน */
}
