/* Container */
.CutHistory-container {
    width: 70vw;
    max-width: 1100px; /* กำหนดขนาดสูงสุดของ container */
    margin: 60px 50px;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #ffffff, #f2f6fc);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    overflow-x: auto; /* เพิ่ม scroll หากหน้าจอเล็ก */
}

/* Title */
.CutHistory-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-transform: uppercase;
}

/* Filter section */
.CutHistory-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* background: #eef2f7; */
    padding: 12px;
    border-radius: 8px;
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); */
}

.CutHistory-label {
    font-size: 18px;
    font-weight: 600;
    margin-right: 12px;
    color: #444;
}

.CutHistory-select {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #007bff;
    border-radius: 6px;
    background: white;
    color: #333;
    font-weight: 500;
    transition: 0.3s;
}

.CutHistory-select:hover {
    background: #007bff;
    color: white;
    cursor: pointer;
}

/* Table Container */
.CutHistory-table-container {
    overflow-x: auto; /* ทำให้ตารางมี scroll ถ้าข้อมูลล้น */
    width: 100%;
    max-width: 100%;
}

/* Table */
.CutHistory-table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    table-layout: auto; /* ปรับขนาดคอลัมน์ให้เหมาะสม */
    word-wrap: break-word;
}

.CutHistory-header {
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: white;
    text-transform: uppercase;
}

.CutHistory-th{
    background: linear-gradient(135deg, #ff416c, #ff4b2b);
    text-align: center;
}

 .CutHistory-td {
    padding: 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-break: break-word; /* ป้องกันข้อความยาวไม่ให้ล้น */
    max-width: 200px; /* จำกัดความกว้างของเซลล์ */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* ตัดคำที่ยาวเกิน */
}

.CutHistory-th {
    font-weight: bold;
}

.CutHistory-row {
    transition: 0.3s;
}

.CutHistory-row:hover {
    background-color: rgba(0, 123, 255, 0.1);
    transform: scale(1.01);
}

/* Alternate row colors */
.CutHistory-row:nth-child(even) {
    background-color: #f8f9fc;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .CutHistory-table-container {
        overflow-x: auto;
    }

    .CutHistory-th, .CutHistory-td {
        white-space: nowrap; /* ป้องกันการขึ้นบรรทัดใหม่ */
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* ปรับขนาดและสไตล์ของปุ่มให้เหมือนกัน */
.CutHistory-clear-button,
.CutHistory-cut-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 12px 20px; /* ทำให้ padding เท่ากัน */
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: flex;
    align-items: center; /* จัดให้อยู่ตรงกลางแนวตั้ง */
    height: 44px; /* กำหนดความสูงให้เท่ากัน */
}

/* ปุ่ม Clear */
.CutHistory-clear-button {
    background-color: #ff4d4d;
}

.CutHistory-clear-button:hover {
    background-color: #cc0000;
}

.CutHistory-clear-button:active {
    background-color: #990000;
}

.CutHistory-clear-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* ปุ่ม Cut */
.CutHistory-cut-button {
    background-color: #4CAF50;
}

.CutHistory-cut-button:hover {
    background-color: #388E3C;
}

.CutHistory-cut-button:active {
    background-color: #2E7D32;
}

.CutHistory-cut-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Container สำหรับปุ่ม */
.CutHistory-button-group {
    display: flex;
    justify-content: flex-start; /* จัดปุ่มไปทางซ้าย */
    gap: 15px; /* เพิ่มระยะห่างระหว่างปุ่ม */
    margin-bottom: 15px;
    padding-left: 10px;
    align-items: center; /* ทำให้ปุ่มอยู่ในระดับเดียวกัน */
}
